@import 'src/style/shared.scss';

.header__navContent {
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  background-color: var(--theme-bg);
  transition: opacity 0.3s $ease-out-quad 0.3s, visibility 0s 0.7s, transform 0s 0.7s;
  &--mobileOpen {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: opacity 0.3s $ease-out-quad, visibility 0s ease 0s;
  }
  @include media('>=large') {
    background-color: transparent !important;
    margin-left: auto;
    width: auto;
    position: relative;
    height: auto;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

nav.main-nav {
  display: flex;
  @include fill;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding-left: px(35);
  @include media('>=medium') {
    padding-left: px(55);
  }
  @include media('>=large') {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: auto;
    position: relative;
    top: auto;
    left: auto;
    margin-left: auto;
  }
}

ul.main-nav__menu {
  @include clean;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin-top: px(30);
  @include media('>=large') {
    margin-top: 0;
    margin-right: px(5);
    @include fill;
    display: flex;
    flex-direction: row;
  }
}
li.main-nav__menu-li {
  position: relative;
  margin-bottom: var(--space-lg);
  @include media('>=large') {
    &:first-child {
      display: none;
    }
    height: 100%;
    padding-left: px(35);
    margin-bottom: 0;
  }
  @include media('>=hd') {
    padding-left: px(40);
  }
  @include media('>=mlarge') {
    padding-left: px(45);
  }
  @include media('>=fullhd') {
    padding-left: px(50);
  }
  a {
    color: var(--theme-color);
    font-weight: 300;
    display: block;
    letter-spacing: 1.5px;
    margin: auto;
    height: 100%;
    position: relative;
    line-height: 1;
    text-decoration: none;
    font-size: calc(var(--text-xl) * 1.1);
    transition: color 0.3s ease;
    @include media('>=medium') {
      font-size: calc(var(--text-xxl) * 1.1);
    }
    @include media('>=large') {
      align-self: center;
      display: flex;
      text-transform: uppercase;
      font-weight: 700;
      font-size: calc(var(--text-sm) * 0.9);
      padding: px(10) 0;
      letter-spacing: 0.15rem;
    }
    @include media('>=mlarge') {
      letter-spacing: 0.18rem;
    }
    @include media('>=mlarge') {
      letter-spacing: 0.24rem;
    }
    &.active {
      font-weight: 700;
    }
    &:visited,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
    // &:hover {
    //   color: $white;
    // }
  }
}
