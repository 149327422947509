@import 'src/style/shared.scss';

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  @include z-index(main-nav);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  padding: px(16) px(10) 0;
  height: px(35);
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.6s $ease-out-circ, opacity 0.3s ease;

  &:before {
    content: '';
    z-index: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 300%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to bottom, rgba($darkBlue, 0.9), rgba($darkBlue, 0));
    .light-mode & {
      background: linear-gradient(to bottom, rgba($light, 0.9), rgba($light, 0));
    }
  }

  @include media('>=medium') {
    padding: px(25) px(25) 0;
    height: px(45);
  }
  @include media('>=large') {
    padding: px(26) px(35) 0;
    height: auto;
    &:before {
      height: 260%;
      background: linear-gradient(to bottom, rgba($darkBlue, 0.7) 30%, rgba($darkBlue, 0));
      .light-mode & {
        background: linear-gradient(to bottom, rgba($light, 0.8) 30%, rgba($light, 0));
      }
    }
  }
  @include media('>=hd') {
    padding: px(27) px(45) 0;
  }
  @include media('>=mlarge') {
    padding: px(28) px(55) 0;
  }
  @include media('>=fullhd') {
    padding: px(30) px(75) 0;
  }
  .video-isPlaying & {
    opacity: 0;
  }
  &--isHidden {
    transform: translateY(-200%);
    transition: transform 0.7s $ease-out-cubic;
  }
  &__brandContent {
    z-index: 2;
    h1 {
      @include clean;
    }
    h1 > span,
    h1 .site-description {
      @extend %hide-element;
    }

    a.main-logo {
      display: flex;
      @include clean;
      width: px(120);
      transition: width 0.3s ease;
      // height: px(80);
      @include media('>=medium') {
        width: px(160);
      }
      @include media('>=large') {
        width: px(180);
      }
      @include media('>=hd') {
        width: px(200);
      }
      @include media('>=mlarge') {
        width: px(220);
      }
      @include media('>=fullhd') {
        width: px(240);
      }

      img,
      svg {
        @include clean;
        width: 100%;
        height: px(35);
        .dark-mode & {
          fill: white;
        }
      }
    }
  }
  &.Header--dark {
    --theme-color: white;

    &:before {
      background: linear-gradient(to bottom, rgba($black, 0.3), rgba($black, 0));
    }

    .Header__brandContent {
      a.main-logo {
        img,
        svg {
          fill: white;
        }
      }
    }
  }
  &__mobileContent {
    z-index: 2;
    margin-left: auto;
    // width: px-to-rem(32);
    // height: px-to-rem(15);
    background-color: transparent;
    @include media('>=large') {
      display: none;
    }
  }
  &__tools {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    width: 88%;
    height: px(20);
    position: absolute;
    text-align: right;
    top: 130%;
    right: px(10);
    color: var(--theme-color);
    padding-top: px(7);
    border-top: 1px solid var(--theme-color);
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.7s $ease-out-cubic;
    @include media('>=xsmall') {
      width: 90%;
    }
    @include media('>=medium') {
      padding-top: px(10);
      top: 140%;
      width: px(190);
      height: px(20);
      right: px(25);
      color: var(--theme-color);
    }
    @include media('>=large') {
      width: px(220);
      top: 120%;
      right: px(35);
    }

    @include media('>=hd') {
      right: px(45);
    }
    @include media('>=mlarge') {
      right: px(55);
    }
    @include media('>=fullhd') {
      right: px(75);
    }

    &__greetings {
      opacity: 0.56;
      margin-right: px(10);
      font-size: calc(var(--text-xs) * 1.3);
      @include media('>=xsmall') {
        font-size: calc(var(--text-xs) * 1.4);
      }
      @include media('>=medium') {
        font-size: calc(var(--text-xs) * 1.3);
      }
      @include media('>=large') {
        font-size: calc(var(--text-xs) * 1.2);
      }
    }
  }
  &--mobileNavisHidden {
    .Header__tools {
      opacity: 0;
      transform: translateY(-50%);
    }
  }
}
