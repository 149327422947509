@import 'src/style/shared.scss';

.CustomCursor {
  position: fixed;
  height: 60px;
  width: 60px;
  pointer-events: none;
  z-index: 999;
  transition: width 0.3s ease-out, height 0.3s ease-out, background-color 0.3s ease;
  span {
    display: block;
    position: absolute;
    @include fill;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 1px solid black;
    background-color: rgba($white, 0);
    transition: width 0.3s ease-out, height 0.3s ease-out, background-color 0.3s ease;
    .dark-mode & {
      border: 1px solid white;
    }
  }
  &--sorter {
    width: 30px;
    height: 30px;
    span {
      border: 0;
      background-color: rgba($red, 0.4);
      filter: blur(8px);
      .dark-mode & {
        border: 0;
        background-color: rgba($white, 0.3);
      }
    }
  }
  &--pointer {
    width: 90px;
    height: 90px;
    span {
      border: 0;
      background-color: rgba(darken($red, 4%), 0.4);
      filter: blur(12px);
      .dark-mode & {
        border: 0;
        background-color: rgba($white, 0.3);
      }
    }
  }
  &--video {
    width: px(100);
    height: px(100);
    span {
      border: 0;
      background-color: rgba(darken($red, 4%), 0.4);
      transform: translate(-42%, -50%);
      filter: blur(12px);
      .dark-mode & {
        border: 0;
        background-color: rgba($white, 0.3);
      }
    }
  }
  // &--hovered {
  //   background: transparent !important;
  //   width: 56px;
  //   height: 56px;
  //   border: 4px solid #ea281e;
  //   border: 4px solid #ea281e;
  // }
  // &--locked {
  //   background: transparent !important;
  //   width: 56px;
  //   height: 56px;
  // }
}
