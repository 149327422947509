[hidden] {
  display: none !important;
}

::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}
