@import 'shared.scss';

#root {
  .PageOverlay {
    @include z-index(page-overlay);
  }

  #Preloader {
    @include flex-center;
    @include position-100(fixed);
    background-color: $black;
  }

  .Footer {
    opacity: 0;
    @include z-index(foter);
    display: flex;
    position: relative;
    align-items: center;
    padding: var(--space-md) var(--space-sm);
    text-align: center;
    background-color: var(--main-white);
    color: var(--main-black);

    @include media('>=medium') {
      padding: var(--space-lg) px(25) var(--space-md);
    }

    @include media('>=large') {
      padding: var(--space-xl) px(35) var(--space-lg);
      position: absolute;
      bottom: 0;
    }
    @include media('>=hd') {
      padding: var(--space-xl) px(45) var(--space-lg);
    }
    @include media('>=mlarge') {
      padding: var(--space-xl) px(55) var(--space-lg);
    }
    @include media('>=fullhd') {
      padding: var(--space-xl) px(75) var(--space-lg);
    }

    .footer-brand {
      width: 45%;
      display: flex;
      flex-direction: column;
      order: 1;
      text-align: left;
      padding-right: 10%;
      @include media('>=large') {
        width: 40%;
      }
      @include media('>=mlarge') {
        width: 30%;
      }
      @include media('>=fullhd') {
        width: 40%;
      }
      a {
        margin-bottom: var(--space-sm);
        width: px-to-rem(100);
        @include media('>=medium') {
          width: px(140);
        }
        @include media('>=large') {
          width: px(160);
        }
        @include media('>=hd') {
          width: px(180);
        }
        @include media('>=mlarge') {
          width: px(200);
        }
        @include media('>=fullhd') {
          width: px(220);
        }
        img {
          width: 100%;
        }
      }
      span {
        font-size: var(--text-xs);
      }
    }
    .footer-nav {
      order: 2;
      width: 50%;
      padding-left: 10%;
      color: var(--main-black);
      text-align: left;
      @include media('>=large') {
        padding-left: 0;
        text-align: right;
        width: 70%;
      }
      @include media('>=fullhd') {
        width: 60%;
      }
      .nav-list {
        li {
          display: block;
          font-size: auto;
          font-size: var(--text-sm);
          margin-bottom: px-to-rem(8);
          @include media('>=large') {
            margin-bottom: 0;
            padding: 0 px(10);
            display: inline-block;
          }
          a {
            text-decoration: none;
            color: var(--main-black);
          }
        }
      }
    }
    .footer-copyright {
      display: none;
    }
  }

  .RotateScreen {
    @include z-index(rotate);
  }
}

.vs-scrollbar.vs-vertical {
  z-index: 100 !important;
  top: 10% !important;
  height: 70% !important;
  width: px(1) !important;
  right: px(13) !important;
  overflow: hidden;
  transform: scale(1, 0.5) !important;
  .vs-scrolldrag {
    background: var(--theme-color) !important;
  }
}

.CookieConsent {
  background: rgba($black, 0.92);
  color: $white;
  position: fixed;
  text-align: center;
  padding: var(--space-md);
  bottom: px-to-rem(10) !important;
  right: px-to-rem(10);
  height: px-to-rem(145);
  width: 94%;
  z-index: 200;
  @include media('>=medium') {
    width: px-to-rem(340);
    height: px-to-rem(150);
  }
  @include media('>=large') {
    width: px-to-rem(400);
    height: px-to-rem(170);
    bottom: px-to-rem(20) !important;
    right: px-to-rem(20);
  }
  > div {
    font-size: calc(var(--text-sm) * 1.2);
    margin-bottom: px-to-rem(20);
    @include media('>=large') {
      font-size: var(--text-sm);
    }
  }
  button {
    color: $black;
    padding: px-to-rem(10) px-to-rem(20);
    background-color: $white;
    text-transform: uppercase;
    font-size: var(--text-xs);
    font-weight: 700 !important;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: darken($white, 8%);
      transition: background-color 0.3s ease;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}
