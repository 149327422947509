$base-path: '../../assets/fonts/';

@font-face {
  font-family: 'LeagueSpartan';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('#{$base-path}LeagueSpartan/LeagueSpartan-Light.woff') format('woff'),
    url('#{$base-path}LeagueSpartan/LeagueSpartan-Light.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'LeagueSpartan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$base-path}LeagueSpartan/LeagueSpartan-Regular.woff') format('woff'),
    url('#{$base-path}LeagueSpartan/LeagueSpartan-Regular.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'LeagueSpartan';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$base-path}LeagueSpartan/LeagueSpartan-Medium.woff') format('woff'),
    url('#{$base-path}LeagueSpartan/LeagueSpartan-Medium.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'LinLibertine';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('#{$base-path}LinLibertine/LinLibertine.woff') format('woff'),
    url('#{$base-path}LinLibertine/LinLibertine.ttf') format('truetype');
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
