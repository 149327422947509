@import 'src/style/shared.scss';

.Header__tools__darkMode {
  opacity: 0.8;
  width: px(75);
  height: px(20);
  display: flex;
  justify-content: center;
  .darkModeBtn {
    position: relative;
    svg {
      @include fill;
      fill: var(--theme-color);
    }
    &--dark {
      margin-right: px(5);
      // width: px(10);
      // height: px(10);
      svg {
        top: px(-2);
        position: relative;
        width: px(10);
        height: px(10);

        // @include media('>=large') {
        //   top: px(-1);
        // }
      }
    }
    &--light {
      margin-left: px(5);
      top: px(-1);
      svg {
        width: px(12);
        height: px(12);

        // @include media('>=large') {
        //   top: px(-1);
        // }
      }
    }
  }
  .react-toggle-wrapper {
    @include clean;
    position: relative;
    top: px(2);
    height: px(14);
  }
  .react-toggle {
    @include clean;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    .react-toggle-track-check,
    .react-toggle-track-x {
      display: none;
    }
    .react-toggle-thumb {
      width: 16px;
      height: 16px;
      box-shadow: none !important;
      background-color: $light;
    }
    .react-toggle-track {
      width: 40px;
      height: 18px;
      background-color: transparent !important;
      border: 1px solid var(--theme-color) !important;
    }
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 24px;
    border-color: var(--theme-color);
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: transparent;
  }
}
