:root {
  --color-red: #d06056;

  --main-white: white;
  --main-black: #000000;
  --main-gray: #474747;

  --grey-9: #232323;
  --grey-8: #353535;
  --grey-7: #474747;
  --grey-6: #757575;
  --grey-5: #a3a3a3;
  --grey-4: #bfbfbf;
  --grey-3: #dadada;
  --grey-2: #ededed;
  --grey-1: #f6f6f6;

  --theme-color: black;
  --theme-bg: #f5eeeb;
}

body.dark-mode {
  --theme-color: white;
  --theme-bg: #13111E;
}

.color-text-green {
  color: var(--color-green);
}
.color-text-blue {
  color: var(--color-blue);
}
